import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from '../environments/environment';
import { UserService } from './user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private googleAuthUrl = 'https://accounts.google.com/o/oauth2/auth';
  private clientId = '32155575098-phku9lhug6c64b6lk1r8ktc1opbmr80q.apps.googleusercontent.com';
  private redirectUri = 'https://app.vidget.com.br/auth/callback';
  private responseType = 'token';
  private responseTypeGA = 'code';
  private clientSecret = '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'; // ⚠️ Apenas para testes em ambiente controlado
  private scope = 'https://www.googleapis.com/auth/tagmanager.readonly https://www.googleapis.com/auth/tagmanager.edit.containers https://www.googleapis.com/auth/tagmanager.publish https://www.googleapis.com/auth/tagmanager.edit.containerversions https://www.googleapis.com/auth/tagmanager.manage.accounts https://www.googleapis.com/auth/tagmanager.manage.users';
  private scopeGA = 'https://www.googleapis.com/auth/analytics.readonly  https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.manage.users';
  private accessToken: string | null = null;
  private tokenEndpoint = 'https://oauth2.googleapis.com/token';

  constructor(private http: HttpClient, private userService: UserService, private router: Router) { }
  handleAuthCallbackGA(): void {
    const params = new URLSearchParams(window.location.search);
    const authorizationCode = params.get('code');

    if (authorizationCode) {
      console.log('Authorization Code for GA:', authorizationCode);
      this.exchangeAuthorizationCodeForTokensGA(authorizationCode);
    } else {
      console.error('No Authorization Code found in the URL.');
    }
  }

  private exchangeAuthorizationCodeForTokensGA(authorizationCode: string): void {

    const body = {
      code: authorizationCode,
      client_id: this.clientId,
      client_secret: this.clientSecret,
      redirect_uri: this.redirectUri,
      grant_type: 'authorization_code',
    };
    console.log(body)
    this.http.post<any>(this.tokenEndpoint, body).subscribe(
      (response) => {
        console.log('Tokens for GA:', response);

        this.accessToken = response.access_token;
        const refreshToken = response.refresh_token;

        localStorage.setItem('ga_access_token', this.accessToken || '');
        localStorage.setItem('ga_refresh_token', refreshToken || '');
        this.userService.updateRefreshToken(refreshToken)

        console.log('Access Token and Refresh Token for GA stored locally.');
      },
      (error) => {
        console.error('Error exchanging Authorization Code for GA:', error);
      }
    );
  }

  handleAuthCallback(): void {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const accessToken = params.get('access_token');

    console.log('Access Token from URL:', accessToken);

    if (accessToken) {


      const integrationType = localStorage.getItem('integration_service');
      if (integrationType === 'GA') {
        this.setAccessTokenGA(accessToken);
        localStorage.setItem('openGaModal', 'true');
        console.log('openGaModal set to true');
      } else if (integrationType === 'GTM') {
        this.setAccessToken(accessToken);
        localStorage.setItem('openGtmModal', 'true');
        console.log('openGtmModal set to true');
      }

    } else {
      console.warn('No tokens found in the URL.');
    }

    localStorage.removeItem('integration_service');
  }


  refreshAccessToken(refreshToken: string) {
    const body = {
      client_id: this.clientId,
      client_secret: this.clientSecret,
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
      scope: this.scopeGA
    };

    return this.http.post<any>(this.tokenEndpoint, body).pipe(
      switchMap((response) => {
        console.log('Novo Access Token:', response.access_token);
        localStorage.setItem('ga_access_token', response.access_token);

        return of(response.access_token);
      })
    );
  }
  // GTM Authentication URL
  getAuthUrl(): string {
    return `${this.googleAuthUrl}?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&response_type=${this.responseType}&scope=${encodeURIComponent(this.scope)}&prompt=consent`;
  }

  // GA Authentication URL
  getAuthUrlGA(): string {
    return `${this.googleAuthUrl}?client_id=${this.clientId}&redirect_uri=${this.redirectUri}&response_type=${this.responseTypeGA}&scope=${encodeURIComponent(this.scopeGA)}&access_type=offline&prompt=consent`;
  }

  // GTM Access Token Methods
  setAccessToken(token: string): void {
    localStorage.setItem('gtm_access_token', token);
  }

  getAccessToken(): string | null {
    return localStorage.getItem('gtm_access_token');
  }

  // GA Access Token Methods
  setAccessTokenGA(token: string): void {
    localStorage.setItem('ga_access_token', token);
  }

  getAccessTokenGA(): string | null {
    return localStorage.getItem('ga_access_token');
  }

  saveTokensToSession(): void {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.endsWith('-auth-token')) {
        const value = localStorage.getItem(key);
        if (value) {

          sessionStorage.setItem(key, value);
          console.log(`Token do Supabase salvo no sessionStorage com a chave: ${key}`);
          return;
        }
      }
    }
    console.warn('Nenhum token válido encontrado no localStorage para salvar no sessionStorage.');
  }

  restoreTokensToLocalStorage(): void {
    for (let i = 0; i < sessionStorage.length; i++) {
      const key = sessionStorage.key(i);
      if (key && key.endsWith('-auth-token')) {
        const value = sessionStorage.getItem(key);
        if (value) {
          localStorage.setItem(key, value);
          console.log(`Token do Supabase restaurado no localStorage com a chave: ${key}`);
          return;
        }
      }
    }
    console.warn('Nenhum token válido encontrado no sessionStorage para restaurar no localStorage.');
  }


}
