import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ModalStateService } from 'src/app/services/modal-state.service';

@Component({
  selector: 'app-auth-callback',
  template: '',
})
export class AuthCallbackComponent implements OnInit {
  constructor(
    private modalStateService: ModalStateService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.handleAuthCallback();
    this.authService.handleAuthCallbackGA();

    this.router.navigateByUrl('/settings').then(() => {
      this.authService.restoreTokensToLocalStorage();

      console.log('Navigation to settings completed');
      console.log('openGtmModal value after navigation:', localStorage.getItem('openGtmModal'));
      if (localStorage.getItem('openGtmModal') === 'true') {
        this.modalStateService.setShowModal(true);
        console.log('Modal should now be open.');
        localStorage.removeItem('openGtmModal'); // Certifique-se de remover a flag apenas se o modal for aberto
      }
    });
  }


}
